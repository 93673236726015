/**
  $theme-default (appelgroenzeeblauw)
  @description: default theme colors more info about setting colors in mixins/_colors.scss
*/

$dark: 3%;
$darker: 6%;

$theme-default:(
  "dark": #000,
  "bright": #FFF,
  "red": #F02233,
  "blue": #0070B8,
  "grey": #8a8B8B,
  "lightgrey": #E7EAEF,
  "darkblue": #103560,
  "verydarkblue": #072549,
  "vividpink": #f18e7a,
  "darkred":#7d2313,
  "lightgrayishblue": #E2E5EB,
  "grayishblue": #C3C4C6,
  "richelectricblue": #0092E0,
  "infoblue": #6c8099,
  "banner-text": #472300,
  "stibmivbblue": #093376,
  "lightpink": #fff5c7,
  "brown": #8A3800,
  "lightblue": #0000FF,
  "darkbrown": #472300
);


/**
  $theme-colors

  @description: overide colors based on body class
  @example:
    "purple": (
      "primary": #696ad1
    )
  @result: when the body class is set to purple the result of @include color("color","primary") will be set to purple
*/

$theme-colors: (
);


/**
  $background-reactions

  @description: reactions to the background color
  @example:
    "black":(
      "a": (
        "color":"accent-dark"
      )
    )
  @result: when the background is set to black the color of a tags will be accent color
*/

$background-reactions:(
  "dark":(
    ".": (
      "color": "bright"
    ),
    "a": (
      "color": "bright"
    ),
    "a:hover": (
      "color": "accent"
    ),
    "a.active": (
      "color": "accent"
    )
  ),
  "grey":(
    ".": (
      "color": "bright"
    ),
    "a": (
      "color": "bright"
    ),
    "a:hover": (
      "color": "bright"
    ),
    "a.active": (
      "color": "bright"
    )
  ),
  "vividpink":(
    ".": (
      "color": "bright"
    ),
    "a": (
      "color": "bright"
    ),
    "a:hover": (
      "color": "bright"
    ),
    "a.active": (
      "color": "bright"
    )
  ),
  "darkblue":(
    ".": (
      "color": "bright"
    ),
    "a": (
      "color": "bright"
    ),
    "a:hover": (
      "color": "bright"
    ),
    "a.active": (
      "color": "bright"
    )
  )
);

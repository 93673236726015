/**
  @mixin color

  @description: The color mixin adds the posibility to add specific color values for specific body classes.
  The color mixin can also react on background colors for example: by default a link could be blue but on a black
  background you want it to be white. All colors and reactions a defined in config/_colors.scss

  $property: the property you want to set color value to
  $color: the name of the color specified in config/_colors.scss
  $bg-reaction: enable or disable the background-reaction functionality

  @example:
    @include color("color", "primary", true)

  @result: when the body class is set to purple the result of @include color("color","primary") will be set to purple
*/

@function color($color){
  @return map-get($theme-default,$color);
}

@mixin color($property,$color, $bg-reaction:true, $darken: 0){

  @if($bg-reaction){
    @if($property == "background"){
      @if(map-has-key($background-reactions,$color)){
        @each $selector,$properties in map-get($background-reactions,$color){
          @if($selector == "."){
            @each $property, $value in $properties{
              @include color($property,unquote($value));
              //@debug $property;
            }
          }@else{
            #{$selector}{
              @each $property, $value in $properties{
                @include color($property,unquote($value));
                //@debug $property;
              }
            }
          }

        }
      }
    }
  }

  @each $segment in $color{
    @if(map-has-key($theme-default,$segment)){


        $newColor : map-get($theme-default,$segment);

        @if ($darken!=0) {
          $newColor: darken($newColor, $darken);
        }

        $newValue: str-replace(to-string($color), to-string($segment), to-string($newColor));

        #{$property}: unquote($newValue);

    }
  }

  @each $theme, $colors in $theme-colors{

    @each $segment in $color{
      @if(map-has-key($colors,$segment)){

        .#{$theme} &{
          $newColor : map-get($colors,$segment);

          @if ($darken!=0) {
            $newColor: darken($newColor, $darken);
          }
          
          $newValue: str-replace(to-string($color), to-string($segment), to-string($newColor));

          #{$property}: unquote($newValue);
          //@debug $newValue;
          //@debug $newColor;

        }
      }
    }

  }

}

.cookie-consent {
    box-sizing: border-box;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    padding: 90px 30px 260px;
    width: 440px;
    max-width: 100%;
    font-size: 16px;
    max-height: 100vh;
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        transform: rotate(-7deg);
        transform-origin: bottom left;
        width: 600px;
        height: 1100px;
        background-image: url('/assets/img/cookie-bg.png');
        background-position: bottom left;
        background-repeat: no-repeat;
        background-color: rgba(white, 0.80);
    }

    @media screen and(max-width:700px) {
        overflow: auto;
        background: #FFF;
        width: auto;
        left: 0;
        &::before {
            transform: rotate(0);
            display: none;
        }
        background-image: url('/assets/img/cookie-bg.png');
        background-position: bottom left;
        background-repeat: no-repeat;
        padding: 90px 45px 260px 30px;
    }  

    &__title {
        font-family: mivbstibout-Regular,Arial,helvetica,sans-serif;
        font-size:40px;
        line-height: 0.98em;
        background-repeat: no-repeat;
        margin-bottom: 30px;
        text-align: left;

        @media screen and(min-width:700px) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 73px;
    
            span {
                display: inline-block;
                font-size: 0.6em;
                line-height: 1em;
                margin: 0 0.1em;
            }
        }


    }

    p {
        margin-bottom: 25px;
    }

    &__info {
        font-weight: bold;
        font-size: 15px;
        color: color('blue');
        text-decoration: underline;
    }

    .btn {
        background-image: none;
        border-right: none;
        font-weight: 800;
    }

}

%inout{
    font-family: 'Trade Gothic LT Std', Arial, helvetica, sans-serif;
    position:relative;

    &.blue:after{
        @include color("color",verydarkblue);
    }
}
h1,h2,h3,h4,h5{
    @extend %inout;
    span{
        @extend %inout;
        display:inline-block;
    }
}

.age-block {
  position:relative;
  .full-click{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:100;
  }
  @include color("background","lightpink");
  @include color("color","dark");
  padding: 20px;
  height: 220px;
  margin-bottom: 20px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  @media(min-width: $screen-xs-max) and (max-width: $screen-sm-max){
    height: 240px;
  }

  @media screen and(min-width:767px ) {
    height:280px;
  }
  @media screen and(min-width:993px){
    height:240px;
  }
  @media screen and(min-width:1200px){
    height:220px;
  }
  .category {

    text-align: center;
    margin-bottom: 10px;

    @media(min-width: $screen-xs-max) and (max-width: $screen-sm-max){
      img {
        width: 100%;
      }
    }

    h3 {
      font-size: 25px;
      text-transform: uppercase;
    }
  }

  a {
    font-weight: bold;
    @include color("color","dark");
    text-decoration: underline;

    &.btn {
      @include color("color","bright");
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.arrow {
    @include color("background","darkbrown");
    @include color("color","bright");
    a.btn {
      visibility: hidden;
    }

    a {
      @include color("color","bright");
    }

  }

  @media(max-width: $screen-xs-max){
    height: auto;
    text-align: center;

    padding: 0;

    a {
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      height: 60px;
      line-height: 60px;
      display: block;
      @include color("color","darkbrown");

      &:after {
        content: '';
        background-image: url('/assets/img/arrow-right.png');
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        top: 15px;
        display: block;
        width: 30px;
        height: 30px;
        @media(max-width: 450px){
          right:3px;
        }
      }
    }


    &.arrow {
      &:after {
        display: none;
      }

      a {
        &:after {
          background-image: url('/assets/img/arrow-down.png');
        }
      }
    }
  }

}

/* footer */

footer {
  @include color("background-color","darkbrown");
  @include color("color", "bright");
  height: 45px; 
  line-height: 45px;
  margin-top: 45px;
  font-family: "brusseline", 'Open Sans', sans-serif;

  #footer-links {
    a {
      font-weight: bold;
      @include color("color", "bright");
    }
  }
}

.footer-push {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -90px; 
}
/* _navbar.scss */

.navbar {
	margin: 0;

    .navbar-toggle {
    	.icon-bar {
    		display: block;
  			width: 22px;
  			height: 2px;
			border-radius: 1px;
			@include color('background','darkblue');
    	}
    }

    .navbar-header {
      padding: 0 0 20px 0;
    }

	.navbar-nav {
		margin: 20px 0 0 0;
	}

	.navbar-text {
		@include color("color","vividpink");
	}

	a {
		@include color("color","darkblue");

		&.vividpink {
			@include color("color","vividpink");
		}
	}
}
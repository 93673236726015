/* header */

header {
	@include color("background","bright");
	font-family: "brusseline", 'Open Sans', sans-serif;
	font-weight: bold;

	a {
		font-family: "brusseline", 'Open Sans', sans-serif;
		font-weight: bold;
	}
}
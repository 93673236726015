body {
  @include color("background","bright");
}

body,html{
  height:100%;
}

#update-browser {
  position: fixed;
  width: 100%;
  z-index: 1;
  @include color("background","lightgrey");
  text-align: center;
  padding: 20px;
}

.site-container{
  display:table;
  height:100%;
  width:100%;
  .site-main{
    display: table-row;
  }
  .site-footer{
    display: table-row;
    height:1px;
  }
  .cell{
    display:table-cell;
  }
}

.clear{
  @include clearfix();
}

.success-message{
  @include color("background","darkblue");
  @include color("color","bright");
  padding:20px;
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:9999;
  .message{
    font-size:1.2em;
    line-height:2em;
  }
  .btn-close{
    float:right;
    @include color("color","bright");
    &:hover{
      @include color("color","vividpink");
    }
  }
}

.footer-push {
  padding-bottom: 185px;
}

.navbar {
  .caret {
    @include color("border-top-color","vividpink");
    margin-top: -15px;
  }

  .dropdown-menu {
    min-width: 64px;
  }
}

#punchline {
  @include color("background","darkblue");
  @include color("color","bright");
  padding:20px;
  p{
    margin:0;
    font-weight: bold;
  }
  strong{
    @include color("color","vividpink");
  }
}

@media(min-width: $screen-xs-max){
  .spacer-right {
    margin-right: 50px;
  }
}

#banner {

  .banner-background {
    width: 100%;
    background-image: url('/assets/img/banner-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container{
    position:relative;

    @media(min-width: $screen-xs-max){
      min-height:180px;
    }
  }
  padding: 0;

  h1 {
    font-size: 45px;
    text-align: center;
    margin:45px auto;
    max-width:400px;
    text-transform: uppercase;
    @include color("color","banner-text");

    @media(max-width: $screen-xs-max){
      margin-top: 30px;
      font-size:45px;
    }

    @media(max-width: $screen-xs-min){
      font-size:37px;
    }
  }

  p {
    text-align: center;
    @include color("color","banner-text");
  }

  img {
    display: block;
    margin: 10px auto;
  }

  .header-image {
    position: absolute;
    bottom: 0;
    left: 0;

    img {
      position:absolute;
      bottom:0;
      right:0;
      margin: 0 auto;

      @media(max-width: $screen-xs-max){
        display:none;
      }
    }
  }

  .baseline {
    img {
      width: 100%;
      max-width: 320px;
    }
  }

}

.btn {
  &.btn-vividpink {
    @include color("background","darkbrown");
    @include color("color","bright");

    position: relative;
  }
}

#who {
  h3{
    @include color("color","darkbrown");
    margin:0;
    top:-10px;
    span{
      font-size:40px;
    }
  }

  .intro {

    a {
      @include color("color","lightblue");
      text-decoration: underline;
    }
    
    & > div  {
      & > ul {
        padding: 0px;
        & > li {
          list-style: inside;
        }
      }
    }

    .home-student-ul {
       @media(min-width: $screen-md-min ){
          padding: 0px;
          list-style: inside;
       }
      
    }
  }
  .age-block.arrow{
    h3{
      span:after{
          @include color("color","darkred");
      }
      @include color("color","bright");
    }
  }

  @include color("background","bright");
  padding-bottom: 40px;

  .btn {
    &.btn-vividpink {
      padding-right: 30px;

      &:after {
        content: "\203A";
        position: absolute;
        right: 5px;
      }
    }
  }
}


.icon-info {
  padding: 3px;
  font-size: 12px;
  @include color("background","darkbrown");
  @include color("color","bright");
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  /*margin: 5px 0 0 -10px;*/
  margin: 7px 7px 7px 5px;
  position:relative;
  z-index:100;
  cursor: pointer;

  @media(max-width: $screen-xs-max){
    float: right;
    margin: 0 auto;
    width: 18px;
    margin: 0 15px 10px 15px;
  }
}

.with-tooltip {
  span.icon-info {
    display: none;
  }
  p.info {
    display: none;
  }
}
@media(max-width: $screen-xs-max){
  label.width-tooltip {
    display: inline-block;
  }
  span.icon-info {
    margin-left: 15px;
    float: right;
  }

  .tooltip-mobile {
    display: inline !important;
    padding: 0 8px 0px;
    float: right;
  }

  p.info.visible-xs {
    display: block;
    margin-left: 0;
  }
}

#how {
  display: none;
  @include color("background","lightpink");
  @include color("color","dark");

  .container{
    padding-top:40px;
  }

  h2 {
    @include color("color","darkbrown");
  }

  .how-info {
    display: none;

    &.active {
      display: block;
    }

    a {
      @include color("color","lightblue");
      text-decoration: underline;
    }
  }
  #application-create-form{
    margin-bottom:0;
  }

  padding-bottom:70px;


}

#application-create-form {
  margin: 50px 0 80px 0;
  @include clearfix();
  .btn.btn-vividpink{
    margin-bottom:20px;
    font-weight: bold;
  }

}

label[for='firstchild'], label[for='legal_guardian']{
  display: inline;
  margin-left:5px;
}

footer {
  line-height: 45px;
  margin-top: -185px; /* negative value of footer height */

  #footer-background {
    height: 176px;
    width: 100%;
    background-image: url('/assets/img/footer-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  #footer-links {
    a {
      margin-left: 45px;

      &:first-child {
        margin-left: 0;
      }
    }

    @media(max-width: $screen-xs-min){
        float:none !important;
        height:auto;
        line-height:2em;
        padding-bottom:10px;
        a{
          display:block;
          margin:0;
        }
    }
  }
  #copyright{
    @media(max-width: $screen-xs-min){
        float:none !important;
        height:auto;
        line-height: 2em;
        padding-top:10px;
    }
  }

}

.col-half-padding-right {
  padding-right: 7.5px;
}
.col-half-padding-left {
  padding-left: 7.5px;
}


.status-info{
      @include color("background","lightgrey");
      .container{
        padding:30px 15px;
        @include color("color","white");
      }
}

.status-form{
    @include color("background","lightgrey");
    @include clearfix();
}

.status-log{
    border-bottom:1px solid #eee;
    @include clearfix();
    padding:20px 0;
    position:relative;
    em{
      position:absolute;
      top:20px;
      right:20px;
      color:#CCC;
      font-size:12px;
    }
}

.map{
  height:200px;
  background:#EEE;
}

.page-intro{
      @include color("background","lightgrey");
      padding:30px 15px;
      p{
        margin:0;
      }
}
.page-content{
  padding:30px 15px;
}

.page-list-tiles{
  padding:0;
  ul{
    margin:0;
    padding:0;
  }
}
.page-tile{
  position:relative;
  list-style:none;
  h3:after{
    display:none;
  }
  a{
    text-decoration: none;
  }
  img{
    width:100%;
  }
  p{
    margin:10px 0;
  }
  .image{
    position:relative;
  }
  .ornament{
    position:absolute;
    right:0;
    bottom:0;
    display:block;
    @include color("background","vividpink");
    @include color("color","bright");
    width:30px;
    height:30px;
    text-align: center;
    line-height: 30px;
    font-size:20px;
    &:hover{
      @include color("background","blue");
    }
  }
}

.captcha-form{
  padding:30px 0;
  max-width:450px;
  margin:0 auto;
  img{
    margin:20px 0;
    border-radius:5px;
    overflow:hidden;
    border:1px solid #CCC;
    width:100%;
  }
  .btn{
    width:auto;
    margin-top:10px;
    margin-bottom:10px;
    float:left;
    margin-right:10px;
  }
  p{
    padding-top:17px;
  }
  .form-control{

  }
}

.captcha-info{
  @include color("background","lightgrey");
  padding:20px 0;
}
.captcha{
  float:none;
}

.photo-icon{
  position:relative;
  display:none;
  background:url(../img/photo-icon.png) no-repeat;
  width:20px;
  height:16px;
  margin-bottom:-2px;
  top:2px;
  @media(max-width: $screen-xs-max){
    display:inline-block;
  }
}

.notification {
  padding-top: 20px;
  padding-bottom: 20px;

  &__inner {
    border-radius: 10px;
    padding: 20px;

    @include color("color", "bright");
    @include color("background-color", "darkblue");
  }

  span {
    font-size: 1.2em;
  }

  strong {
    @include color("color", "richelectricblue");
  }

  a {
    @include color("color", "bright");
    text-decoration: underline;
  }
}

.new-client-block {
  @include color("background-color","stibmivbblue");
  border-radius: 5px;
  text-align: center;
  margin: 45px 0;
  padding: 35px;

  h2 {
    @include color("color", "bright");
    font-size: 25px;
    line-height: 37px;
    margin: 0 0 20px;
    font-family: 'brusseline';

    &:after {
      @include color("color", "bright");
    }
  }

  a {
    @include color("color", "bright");
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  }
}

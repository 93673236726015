#go-page{
    padding:40px 0;
    &.loading{
        background:url(../img/loading.gif) 50% 50%;
        background-repeat:no-repeat;
        min-height: 200px;
    }
    .cols-container{
        @media(min-width: $screen-xs-min){
            display:table;
        }
        width:100%;
        .row{
            margin:0;
            @media(min-width: $screen-xs-min){
                display:table-row;
            }
            width:100%;
        }
        .row-eq-height{
            @media(min-width: $screen-xs-min){
                display:table-cell;
            }
            @media(max-width: $screen-xs-min){
                width:100%;
            }
            float:none;
            position:relative;
            padding-bottom:220px;
            h1{
                font-size:2em;
            }
            .map{
                position:absolute;
                bottom:0;
                left:10px;
                right:10px;
            }
        }
    }
}
/* _general.scss */

* {
    margin: 0;
}

body {
	@include color("background","lightgrey");
	font-family: "brusseline";
	@include color("color","dark");
    font-size: 16px;
	height: 100%;
}

.no-padding {
	padding: 0;
}
nav.navbar .navbar-header{
    padding:0;
}
.visible-xs {
    ul.navbar-nav {
    margin-right:15px;
    }
}
.navbar-brand{
    padding:10px 15px !important;
}

.page-title {
    text-transform: uppercase;
}
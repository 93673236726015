//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  left: -26px !important;
}

// Wrapper for the tooltip content
.tooltip-inner {
  padding: 25px 15px 10px 15px;
  @include color("color","bright");
  @include color("background","darkbrown");
  border-radius: $border-radius-base;

  text-align: left;
  a {
    text-decoration: underline;
    @include color("color","bright");
    &:hover {
      text-decoration: none;
    }
  }
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 40px !important;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    @include color("border-top-color","darkbrown");
  }
  &.top-left .tooltip-arrow {}
  &.top-right .tooltip-arrow {}
  &.right .tooltip-arrow {}
  &.left .tooltip-arrow {}
  &.bottom .tooltip-arrow {}
  &.bottom-left .tooltip-arrow {}
  &.bottom-right .tooltip-arrow {}

  .close-button {
    position: absolute;
    top: 14px;
    right: 10px;
    a {
      @include color("color","bright");

      &:hover {
        text-decoration: none;
      }

      span {
        display: block;
      }
    }
  }
}

@media(max-width: $screen-xs-max){
  .tooltip {
    position: relative;
    top: auto !important;
    left: auto !important;
    clear: both;
    margin: 15px;
  }
  .tooltip-inner {
    width: 100%;
    max-width: 100% !important;
  }
  .tooltip-arrow {
    display: none;
  }
}
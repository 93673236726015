// IF ADDING A FONT HERE, ADD IT TO THE JS ARRAY IN THE pageLoaded FUNCTION!
@font-face {
    font-family: 'billymedium';
    src: url('/assets/fonts/billy-extended-webfont.eot');
    src: url('/assets/fonts/billy-extended-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/billy-extended-webfont.woff2') format('woff2'),
         url('/assets/fonts/billy-extended-webfont.woff') format('woff'),
         url('/assets/fonts/billy-extended-webfont.ttf') format('truetype'),
         url('/assets/fonts/billy-extended-webfont.svg#billymedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Trade Gothic LT Std';
    src: url('/assets/fonts/TradeGothicLTStd-BdCn20.eot');
    src: url('/assets/fonts/TradeGothicLTStd-BdCn20.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2'),
        url('/assets/fonts/TradeGothicLTStd-BdCn20.woff') format('woff'),
        url('/assets/fonts/TradeGothicLTStd-BdCn20.ttf') format('truetype'),
        url('/assets/fonts/TradeGothicLTStd-BdCn20.svg#TradeGothicLTStd-BdCn20') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family:'mivbstibin-Regular';
    src: url('/assets/fonts/mivbstibin_gdi.eot');
    src: url('/assets/fonts/mivbstibin_gdi.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/mivbstibin_gdi.woff') format('woff'),
        url('/assets/fonts/mivbstibin_gdi.ttf') format('truetype'),
        url('/assets/fonts/mivbstibin_gdi.svg#mivbstibin-Regular') format('svg');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-2212;
}

@font-face {
    font-family:'mivbstibout-Regular';
    src: url('/assets/fonts/mivbstibout_gdi.eot');
    src: url('/assets/fonts/mivbstibout_gdi.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/mivbstibout_gdi.woff') format('woff'),
        url('/assets/fonts/mivbstibout_gdi.ttf') format('truetype'),
        url('/assets/fonts/mivbstibout_gdi.svg#mivbstibout-Regular') format('svg');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+000D-20AC;
}



////// BRUSSELINE ////
// REGULAR
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusseline-webfont.eot');
    src: url('/assets/fonts/brusseline-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusseline-webfont.woff') format('woff'),
    url('/assets/fonts/brusseline-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusseline-webfont.svg#brusselineregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
// BOLD
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusselinebol-webfont.eot');
    src: url('/assets/fonts/brusselinebol-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusselinebol-webfont.woff') format('woff'),
    url('/assets/fonts/brusselinebol-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusselinebol-webfont.svg#brusselinebol') format('svg');
    font-weight: bold;
    font-style: normal;

}
// BOLD ITALIC
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusselinebolita-webfont.eot');
    src: url('/assets/fonts/brusselinebolita-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusselinebolita-webfont.woff') format('woff'),
    url('/assets/fonts/brusselinebolita-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusselinebolita-webfont.svg#brusselinebolita') format('svg');
    font-weight: bold;
    font-style: italic;

}
// ITALIC
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusselineita-webfont.eot');
    src: url('/assets/fonts/brusselineita-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusselineita-webfont.woff') format('woff'),
    url('/assets/fonts/brusselineita-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusselineita-webfont.svg#brusselineita') format('svg');
    font-weight: normal;
    font-style: italic;

}
// LIGHT
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusselinelig-webfont.eot');
    src: url('/assets/fonts/brusselinelig-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusselinelig-webfont.woff') format('woff'),
    url('/assets/fonts/brusselinelig-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusselinelig-webfont.svg#brusselinelig') format('svg');
    font-weight: lighter;
    font-style: normal;

}
// LIGHT ITALIC
@font-face {
    font-family: 'brusseline';
    src: url('/assets/fonts/brusselineligita-webfont.eot');
    src: url('/assets/fonts/brusselineligita-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/brusselineligita-webfont.woff') format('woff'),
    url('/assets/fonts/brusselineligita-webfont.ttf') format('truetype'),
    url('/assets/fonts/brusselineligita-webfont.svg#brusselineligita') format('svg');
    font-weight: lighter;
    font-style: italic;

}
// ICONS
@font-face {
    font-family: 'glyphicons-halflings-regular';
    src: url('/assets/fonts/glyphicons-halflings-regular.eot');
    src: url('/assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('/assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('/assets/fonts/glyphicons-halflings-regular.svg#glyphicons-halflings-regular') format('svg');
    font-weight: normal;
    font-style: normal;

}

// force svg in safari
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'billymedium';
		src: url('/assets/fonts/billy-extended-webfont.svg#billymedium') format('svg');
	}
	
    @font-face {
        font-family: 'mivbstibout';
        src: url('/assets/fonts/mivbstibout-webfont.svg#brusselineligita') format('svg');
    }
   /*  @font-face {
        font-family: 'mivbstibin';
        src: url('/assets/fonts/mivbstibin-webfont.svg#brusselineligita') format('svg');
    } */
	////// BRUSSELINE ////
	// REGULAR
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusseline-webfont.svg#brusselineligita') format('svg');
		font-weight: normal;
		font-style: normal;
    }
	// BOLD
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusselinebol-webfont.svg#brusselineligita') format('svg');
		font-weight: bold;
		font-style: normal;
    }
	// BOLD ITALIC
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusselinebolita-webfont.svg#brusselineligita') format('svg');
		font-weight: bold;
		font-style: italic;
    }
	// REGULAR ITALIC
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusselineita-webfont.svg#brusselineligita') format('svg');
		font-weight: normal;
		font-style: italic;
    }
	// LIGHT
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusselinelig-webfont.svg#brusselineligita') format('svg');
		font-weight: lighter;
		font-style: normal;
    }
	// LIGHT ITALIC
    @font-face {
        font-family: 'brusseline';
        src: url('/assets/fonts/brusselineligita-webfont.svg#brusselineligita') format('svg');
		font-weight: lighter;
		font-style: italic;
    }
    @font-face {
        font-family: 'glyphicons-halflings-regular';
        src: url('/assets/fonts/glyphicons-halflings-regular.svg#glyphicons-halflings-regular') format('svg');
    }
}
/* form.scss */
form {
  select {
      border: none;
      color: #FFF;
      font-size: inherit;
      margin: 0;
      overflow: hidden;
      padding-top: 2px;
      padding-bottom: 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      padding: 12px;
      text-align: center;

      &#status {

      }

      &[name="type"] {
        @include color("background","vividpink");
        @include color("color","bright");
        border: none;
      }
  }

  textarea {
    width: 100%;
    background: #e1e7ed;
    border: 1px solid #acb9c7;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
  }

  .btn {
    width: 100%;
  }

  .info {
    margin-left: -10px;
    font-size: 11px;
    font-style: italic;
    @include color("color","infoblue");
  }

  input {
    &[type="submit"]{
      &:hover {
        text-decoration: underline;
      }
   }
}

.file-upload{
  position:relative;
  .file-val{
    input{
      cursor:default;
      &:disabled{
        background:white;
      }
    }
  }
  label.file-upload-btn{
    top:0;
    right:0;
    bottom:0;
    position:absolute;
    display:inline-block;
    overflow:hidden;
    width:auto;
    text-align: center;
    @include color("background-color","darkbrown");
    @include color("color","bright");
    cursor:pointer;
    &:hover {
      text-decoration: underline;
    }
    span{
      cursor:pointer;
    }
    input{
      position:absolute;
      bottom:100%;
      opacity: 0;
      filter: alpha(opacity=0);
      //cursor:pointer;
    }
  }
 }
}

.form-required{
  @include color("color","brown");
}

.required{
    &:after{
        display:inline;
        content:'*';
        @include color("color","brown");
    }
}

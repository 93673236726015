@font-face {
	font-family: 'backtoschool-icons';
	src:url('/assets/fonts/icomoon.eot?15e2xv');
	src:url('/assets/fonts/icomoon.eot?#iefix15e2xv') format('embedded-opentype'),
		url('/assets/fonts/icomoon.ttf?15e2xv') format('truetype'),
		url('/assets/fonts/icomoon.woff?15e2xv') format('woff'),
		url('/assets/fonts/icomoon.svg?15e2xv#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'backtoschool-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.vividpink {
		@include color("color","vividpink");
	}

	&.blue {
		@include color("color","darkblue");
	}

	&.grey {
		@include color("color","grey");
	}
}

.icon-check:before {
	content: "\e600";
}
.icon-disapprove2:before {
	content: "\e601";
}
.icon-disapprove:before {
	content: "\e606";
}
/*.icon-disapprove {
	-webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}*/

.icon-download:before {
	content: "\e602";
}
.icon-print:before {
	content: "\e603";
}
.icon-speechbubble:before {
	content: "\e604";
}
.icon-file-pdf:before {
	content: "\eada";
}

.icon-info:before {
	content: "\e605";
}